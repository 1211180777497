
    <template>
        
        <div style="padding: 20px;height: 100%;position: relative;">
            <div class="l_invitation_three">
                <div class="l-inviation-left">
                    <swiper ref="mySwiper" :options="swiperOptions" style="min-height: 100%;">
                        <swiper-slide class="muban-item" v-for="info in StoretableData" :key="info.MaterialID">
                            <img class="thumb-img" :src="StoreGetsrc(info)" />
                            <div class="sc-txt">{{info.Name}}</div>
                            <a-button style="margin-bottom:50px" @click="UseTemapate(info)">使用该模板</a-button>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <!-- <div class="muban">
                        <div class="muban-item" v-for="info in StoretableData" :key="info.MaterialID">
                            
                        </div>
                    </div> -->
                </div>
                <div class="l-inviation-rt">
                    <div class="l-inviation-rt-tt">
                        奖项配置
                    </div>
                    <a-modal
                    v-model="TypeShow"
                    title="奖项类型"
                    centered
                    @ok="checked()"
                    >
                        <a-radio-group v-model="TypeText" >
                            <a-radio value="红包">
                                红包
                            </a-radio>
                            <a-radio value="优惠券">
                                优惠券
                            </a-radio>
                            
                        </a-radio-group>
                        <a-radio-group v-if="TypeText=='优惠券'" v-model="CouponActive">
                            <a-radio v-for="(item,index) in CouponsDataList"
                                        :value="item"
                                        :key="index" style="display: flex;align-items: center; margin:15px 0">
                                <div class="l_select_coupon_item">
                                    <!--<img src="https://p3-juejin.byteimg.com/tos-cn-i-k3u1fbpfcp/c89c61a0e8934adf8287887f3b622c8a~tplv-k3u1fbpfcp-no-mark:480:400:0:0.awebp" alt="">-->
                                      <img :src="item.Pic" alt="">  
                                    <div class="title">
                                        <div class="l_popup_item_main_price">
                                            {{item.Cash}}元
                                        </div>
                                        <div class="l_popup_item_main_info">
                                            <span class="l_popup_item_main_info_title">
                                                {{item.Name}}
                                            </span>
                                        </div>
                                    </div>
                                    <span>库存:{{item.Count}}</span>
                                </div>

                            </a-radio>
                        </a-radio-group>
                    </a-modal>
                    <a-drawer
                        title="优惠券列表"
                        placement="right"
                        :visible="visible"
                        @close="visible=false"
                        width="30%"
                        >
                        
                        <div
                            :style="{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                            zIndex: 1,
                            }"
                        >
                            <a-button type="primary" @click="addPf">
                                添加
                            </a-button>
                        </div>
                    </a-drawer>
                    <a-tabs type="editable-card" @edit="edit">
                        <a-tab-pane v-for="pane in PrizeList" :key="pane.key" :tab="pane.tab" >
                            <div class="l-inviation-rt-rd" v-if="pane.AwardType==0">
                                <a-row :gutter="24">
                                    <a-col :span="8" >
                                        <a-form-item label="红包" style="display: flex;">
                                            <a-input-number :min="0.3" :step="0.1" v-model="pane.AmountMoney" />
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8" >
                                        <a-form-item label="奖品数量" style="display: flex;flex: 1;">
                                            <a-input-number v-model="pane.AwardCount" />
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="8" >
                                        <a-form-item label="中奖概率" style="display: flex;">
                                            <a-input-number :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" :min="0" :max="100" v-model="pane.AwardProbability"/>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="l-inviation-rt-pf" v-if="pane.AwardType==1">
                                <div class="l-inviation-rt-pf-itm">
                                    <!--<img src="https://p3-juejin.byteimg.com/tos-cn-i-k3u1fbpfcp/c89c61a0e8934adf8287887f3b622c8a~tplv-k3u1fbpfcp-no-mark:480:400:0:0.awebp" alt="">-->
                                    <div class="pf-itm-info">
                                        <div class="pf-itm-title">
                                            {{pane.title}}
                                        </div>
                                        <div class="pf-itm-input">
                                            <a-col :span="10" >
                                        <a-form-item label="奖品数量" style="display: flex;flex: 1;">
                                            <a-input-number v-model="pane.AwardCount" />
                                        </a-form-item>
                                    </a-col>
                                    <a-col :span="10" >
                                        <a-form-item label="中奖概率" style="display: flex;">
                                            <a-input-number :formatter="value => `${value}%`" :parser="value => value.replace('%', '')" :min="0" :max="100" v-model="pane.AwardProbability" />
                                        </a-form-item>
                                    </a-col>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>

                <div class="l-inviation-btns">
                    <div class="l_invitation_next_btn" v-show="SetInteractionAward">
                        <button @click="gotonext()">下一步</button>
                    </div>
                    <div class="l_invitation_next_btn" v-show="EditInteractionAward">
                        <button @click="gotosaveedit()">保存</button>
                    </div>
                </div>
                <!-- <div class="l_invitation_three_list">
                    <div class="l_invitation_three_item" v-for="(item,index) in PrizeList" :key="index">
                        <div class="l_invitation_three_item_number">
                            奖项{{index+1}}
                        </div>

                        <div class="l_invitation_three_item_main">
                            <div class="l_invitation_close">
                            </div>
                            <div class="l_invitation_three_item_top">
                                <img :src="item.AvatarUrl" alt="">
                                <div class="title" v-if="item.AwardType==1">{{ item.AwardMoney}}元{{item.title}}</div>
                                <div class="title" v-else>
                                    <span>红包:</span>
                                    <div class="l_invitation_prize_input">
                                        <input type="number" v-model="item.AmountMoney" min="0.3" @change="MinMoneys(item)">
                                        <span>元</span>
                                    </div>
                                </div>
                            </div>
                            <div class="l_invitation_three_item_nums">
                                <div class="l_invitation_three_item_nums_input">
                                    <span>奖品数量</span>
                                    <div class="l_invitation_prize_input">
                                        <input type="number" v-model="item.AwardCount" min="1" @change="AwardNums(item)">
                                    </div>
                                </div>
                                <div class="l_invitation_three_item_nums_input ">
                                    <span>中奖概率</span>
                                    <div class="l_invitation_prize_input">
                                        <input type="number" v-model="item.AwardProbability" @change="Probability(item)">
                                        <span>%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <a-button type="primary" @click="PrizeTypeShow=true">
                    +增加奖项
                </a-button>
                <a-modal v-model="PrizeTypeShow" title="新增奖项" on-ok="handleOk">
                    <a-form-model-item round style="border-radius:5px;width:80%">
                    <div class="l_prize_Type_main">
                        <div class="l_prize_Type_main_item" @click="AddRedPrize">
                            <img src="../../../../public/Image/icon-red.svg" width="60" />
                            <span>红包</span>
                        </div>
                        <div class="l_prize_Type_main_item" @click="CouponShow=true">
                            <img src="../../../../public/Image/icon-yhq.svg" width="60" />

                            <span>优惠券</span>
                        </div>

                    </div>
                </a-form-model-item>
                </a-modal>
                



                <a-form-model-item v-model="CouponShow" round position="bottom" :style="{ height: '40%' }">
                    <div class="l_select_coupon">
                        <div class="l_select_coupon_title">
                            添加优惠券
                        </div>
                        <a-checkbox-group v-model="CouponActive">
                            <a-checkbox v-for="(item,index) in CouponsDataList"
                                        @click="SelectCoupon(item)"
                                        :value="item"
                                        :key="index">
                                <div class="l_select_coupon_item">
                                    <img :src="item.Pic" alt="">
                                    <div class="title">
                                        <a class="l_popup_item_main_price">
                                            {{item.Cash}}元
                                        </a>
                                        <a class="l_popup_item_main_info">
                                            <span class="l_popup_item_main_info_title">
                                                {{item.Name}}
                                            </span>
                                        </a>
                                    </div>
                                    <span>库存:{{item.Count}}</span>
                                </div>

                            </a-checkbox>
                        </a-checkbox-group>
                    </div>
                </a-form-model-item>


                <a-form-model-item>
                    <div class="muban">
                        <div class="muban-item" v-for="info in StoretableData" :key="info.MaterialID">
                            <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
                            <div class="sc-txt">{{info.Name}}</div>
                            <a-button style="margin-left:80px" @click="UseTemapate(info)">使用该模板</a-button>
                        </div>
                    </div>
                </a-form-model-item>




                <div class="l_invitation_next_btn" v-show="SetInteractionAward">
                    <button @click="gotonext()">下一步</button>
                </div>
                <div class="l_invitation_next_btn" v-show="EditInteractionAward">
                    <button @click="gotosaveedit()">保存</button>
                </div> -->


            </div>
        </div>




    </template>


<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    export default {
        name: "",
        data() {
            return {
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination'
                    },
                },
                PrizeTypeShow: false,
                CouponShow: false,
                CouponActive: {},
                PrizeList: [],
                CouponsDataList: [],//优惠券数据
                pageLength: 5,
                state: 1,
                pageindex: 1,
                Sumprobability: 0,
                disabled: false,
                StoretableData: [],
                tableData: [],
                ClassID: 9,
                MaterialType: 5,
                Storecurrent: 1,
                StoredefaultPageSize: 12,
                Storetotal: 0,
                TmplID: 0,
                hdid: 2,
                NewID: "",
                Topic: {
                    ID: 10,
                },
                Img: "",
                TmplName: "",
                TmplSummary: "",
                TmplTime: "",
                //AwardPoolConfigVisible: false,
                Model: {
                    fileList: [],
                    Name: "",
                    Author: "",
                    Contents: "",
                    Summary: "",
                    ShareTime: "",
                    AwardPool: [],
                    MaterialAward: {
                        BeginTime: "",
                        EndTime: "",
                        GPSLimit: false,
                        ReadSecond: 1,
                        ReadMoney: 0,
                        PersonMaxMoney: 0,
                        BudgetMoney: 0,
                        MaterialID: "",
                        Name: "",
                        CustomID: "",
                    },
                    MaterialPromoteAwardConfig: {
                        BeginTime: "",
                        EndTime: "",
                        ID: 0,
                        MaterialID: "",
                        AwardType: 0,
                        LuckMoney: 0,
                        AwardPoolID: 0,
                        MaterialAwardPool: [],
                        GetAwardCount: 0,
                        BuyGoodsCount: 0,
                        ReadCount: 0,
                        AwardCount: 1,
                    },
                    Video: [],
                },
                ID: 0,
                SetInteractionAward: true,
                EditInteractionAward: false,
                dbkey: "turndata_",
                activeKey:'',
                panes:[],
                newTabIndex: 0,
                TypeShow:false,
                TypeText:"",
                visible:false,
            };
        },
        props: {
            //组件属性
        },
        methods: {
            edit(targetKey, action){
                this[action](targetKey);
            },
            addPf(){
                if(this.CouponActive.ID!=''){
                    
                    this.addTabs(1,this.CouponActive)
                    this.visible=false
                    
                }else{
                    this.$message.error('请选择奖项类型！');
                }
                
            },
            add() {
                this.TypeShow=true
            },
            checked(){
                if(this.TypeText === ''){
                    return this.$message.error('请选择奖项类型！');
                }
                if(this.TypeText ==='红包'){
                    this.addTabs(0)
                }
                if(this.TypeText ==='优惠券'){
                    this.addPf()
                }
                
                
            },
            addTabs(type,item={}){
                const panes = this.PrizeList;
                const activeKey = `奖项${(this.newTabIndex++)+1}`;
                if(type==0){
                    panes.push({
                        AwardType: type,//红包
                        AwardCount: 0,
                        tab:activeKey,
                        key:activeKey,
                        AwardProbability: 0,
                        AvatarUrl: '../../../../public/Image/icon-red.svg',
                        AmountMoney: 0
                    });
                }
                if(type==1){
                    panes.push({
                    AwardType: 1,//优惠券
                    AwardCount: 0,
                    AwardProbability: 0,
                    AvatarUrl: item.Pic,
                    title: item.Name,
                    tab:activeKey,
                    key:activeKey,
                    AwardMoney: item.Cash,
                    //优惠券ID
                    AwardPoolID: item.ID,

                });
                }
                
                this.PrizeList = panes;
                this.activeKey = activeKey;
                this.TypeShow=false
                this.TypeText='红包'
            },
            remove(targetKey) {
                let activeKey = this.activeKey;
                let lastIndex;
                this.PrizeList.forEach((pane, i) => {
                    if (pane.key === targetKey) {
                    lastIndex = i - 1;
                    }
                });
                const panes = this.PrizeList.filter(pane => pane.key !== targetKey);
                if (panes.length && activeKey === targetKey) {
                    if (lastIndex >= 0) {
                    activeKey = panes[lastIndex].key;
                    } else {
                    activeKey = panes[0].key;
                    }
                }
                this.PrizeList = panes;
                this.activeKey = activeKey;
            },
            //最小奖品数量
            AwardNums(item) {
                var rem = item.AwardCount;
                var index = rem.toString().indexOf(".");
                var spot = rem.toString().substring(0, 1);
                if (spot == ".") {
                    item.AwardCount = rem.toString().substring(1, rem.length);
                    return item.AwardCount;
                }
                if (spot == "0") {
                    var remaining = rem.toString().substring(1, rem.length);
                    item.AwardCount = remaining == "" ? "" : remaining;
                    return item.AwardCount;
                }
                if (index > 0) {
                    item.AwardCount = rem.substring(0, index);
                    return item.AwardCount
                }
                return rem;
            },
            //最小红包金额
            MinMoneys(item) {
                var rem = item.AmountMoney;
                if (rem != null) {
                    var num5 = item.AmountMoney.toString().substring(0, 1);
                    if (num5 == ".")
                        item.AmountMoney = rem.substring(1, rem.length);
                    if (num5 == "0") {
                        var remaining = rem.toString().substring(1, rem.length);
                        item.AmountMoney = remaining == "" ? 0 : remaining;
                    }
                    var index = rem.toString().indexOf(".");
                    if (index > 0) {
                        var num = rem.split('.');
                        var num1 = num[1].substring(0, 1);
                        item.AmountMoney = num[0] + "." + num1;
                    }
                }
                if (item.AmountMoney < 0.3 && item.AmountMoney != "") {
                      self.$message.error("最小激励金额不得小于0.3！");
                    item.AmountMoney = "";
                }
            },
            //中奖概率的限制
            Probability(item) {
                var rem = item.AwardProbability;
                var index = rem.toString().indexOf(".");
                var spot = rem.toString().substring(0, 1);
                if (spot == ".") {
                    item.AwardProbability = rem.toString().substring(1, rem.length);
                    return;
                }
                if (spot == "0") {
                    var remaining = rem.toString().substring(1, rem.length);
                    console.log("remaining:", remaining);
                    item.AwardProbability = remaining == "" ? "" : remaining;
                    return;
                }
                if (index > 0) {
                    item.AwardProbability = rem.substring(0, index);
                    return;
                }
            },
            //下一步
            gotonext: function () {
                var self = this;
                if (self.PrizeList.length < 1) {
                    self.$message.error("请增加奖项！");
                    return;
                }
                if (self.PrizeList.length >= 11) {
                    self.$message.error("奖项数量已超过上限！");
                    return;
                }

                for (var i = 0; i < self.PrizeList.length; i++) {
                    if (self.PrizeList[i].AmountMoney == 0 || self.PrizeList[i].AwardCount == 0) {
                        self.$message.error("红包或奖品数量不能为0");
                        return;
                    }
                    if (self.PrizeList[i].AwardProbability)
                        var Probability = parseInt(self.PrizeList[i].AwardProbability);
                    console.log("asdasdasdasd", Probability);

                    self.Sumprobability += Probability;
                    console.log(self.Sumprobability);
                    if (self.Sumprobability >= 101) {
                        self.$message.error("所有奖项中奖概率总和不能大于100！");
                        self.Sumprobability = 0;
                        return;
                    }
                }
                var PriList = JSON.stringify(self.PrizeList);
                self.setlocal('PrizeList', PriList);

                console.log(self.PrizeList);

                //添加素材(未发布)
                (function (imgpath) {
                    var op = {
                        url: "/MarketingModule/Material/EditMaterial",
                        data: {
                            ID: self.NewID == "" ? self.ID : self.NewID,
                            Name: self.TmplName,
                            Author: self.Model.Author,
                            Summary: self.TmplSummary,
                            Content: self.GetContent(imgpath),
                            MaterialType: self.MaterialType,
                            Topic: self.Topic,
                            Logo: self.Img,
                            TemplateID: self.TmplID,
                            MaterialAward: self.Model.MaterialAward,
                            ShareTime: self.Model.ShareTime,
                            AwardPool: self.Model.AwardPool,
                            Video: self.Model.Video,
                            MaterialPromoteAwardConfig:self.Model.MaterialPromoteAwardConfig,
                        },
                        OnSuccess: function (response) {
                            self.setlocal('MaterialID', response.data);
                            //self.savedata(response.data);
                            self.NewID = response.data;
                            self.loading = false;
                            if (!self.disabled) {
                                self.$message.success("保存成功");
                            }
                            //self.QRCodeVisible = true;
                        },
                    };
                    http.Post(op);

                })
                //(self.tableData[0].PreViewImg || "");

                self.setlocal('MaterialID', self.NewID);


                self.$router.push({
                    name: "InteractionConfig_InteractionPay",
                })
              
                return 0;
            },

            //编辑保存
            gotosaveedit: function () {
                var self = this;
                var AwardDatas = [];
                var sumProbability = 0;
                for (var i = 0; i < self.PrizeList.length; i++) {
                    var itemdata = self.PrizeList[i];
                    if ((parseInt(itemdata.PoolIngCount) + parseInt(itemdata.ReceivedCount)) > itemdata.AwardCount) {
                        self.$message.error("奖品池的数量加已领的数量不能大于奖品数量");
                        return;
                    }
                    sumProbability += parseInt(itemdata.AwardProbability);
                    console.log(sumProbability);
                    if (sumProbability >= 101) {
                        self.$message.error("所有奖项中奖概率总和不能大于100");
                        return;
                    }
                    var d = {
                        "AwardID": itemdata.ID,
                        "AwardProbability": itemdata.AwardProbability,
                        "PoolIngCount": itemdata.PoolIngCount,
                    };
                    AwardDatas.push(d);
                }
                var Datalist = self.getlocal(self.dbkey);
                Datalist = JSON.parse(Datalist);
                console.log("Datalist", Datalist);
                console.log("InvitationTitle", self.getlocal("InvitationTitle"));
                var InvitationTitle =self.getlocal("InvitationTitle");
                var InvitationRule = self.getlocal("InvitationRule");
                var StartTime = self.getlocal("StartTime");
                var EndTime = self.getlocal("EndTime");
                var GeographyLimit =self.getlocal("GeographyLimit");
                var Lat = self.getlocal("Lat");
                var Lng = self.getlocal("Lng");
                var CenterPointAddress =self.getlocal("CenterPointAddress");
                //var MapDistanceStr =this.getlocal("MapDistanceStr");
                var MapDistance = self.getlocal("MapDistance");
                var InitialLuckDrawCount = self.getlocal("InitialLuckDrawCount");
                var MaxWinningPrizeCount = self.getlocal("MaxWinningPrizeCount");
                var HelpMaxCount = self.getlocal("HelpMaxCount");
                var HelpLuckDrawCount = self.getlocal("HelpLuckDrawCount");
                var InviteIntroducerMoney = self.getlocal("InviteIntroducerMoney");
                var InviteSharerMoney = self.getlocal("InviteSharerMoney");
                var FissionShareType = self.getlocal("FissionShareType");

                var op = {
                    url: "/MarketingModule/Promote/EditAwardData",
                    data: {
                        TurnID: self.ID,
                        AwardDatas: AwardDatas,
                        Name: InvitationTitle,
                        StartTime: StartTime,
                        EndTime: EndTime,
                        TurnSummary: InvitationRule,
                        GeographyLimit: GeographyLimit,
                        Lat: Lat,
                        Lng: Lng,
                        MapDistanc: MapDistance,
                        CenterPointAddress: CenterPointAddress,
                        InitialLuckDrawCount: InitialLuckDrawCount,
                        MaxWinningPrizeCount: MaxWinningPrizeCount,
                        HelpMaxCount: HelpMaxCount,
                        HelpLuckDrawCount: HelpLuckDrawCount,
                        InviteIntroducerMoney: InviteIntroducerMoney,
                        InviteSharerMoney: InviteSharerMoney,
                        FissionShareType: FissionShareType,

                    },
                    OnSuccess: function () {
                        self.$message.success("保存成功");
                        self.$router.push({
                            name: "InteractionConfig_List",
                        })
                    }
                };
                http.Post(op);

            },


            GetContent: function (ImgTopic) {
                var self = this;
                var Content = {
                    ID: self.ID,
                    ContextText: self.Model.Contents,
                    ImgTopic: ImgTopic,
                };
                return Content;
            },

            //增加红包
            AddRedPrize() {
                this.PrizeList.push({
                    AwardType: 0,//红包
                    AwardCount: 0,
                    AwardProbability: 0,
                    AvatarUrl: '../../../../public/Image/icon-red.svg',
                    AmountMoney: 0
                })
                this.PrizeTypeShow = false;

            },
            //增加优惠券
            AddCoupon(item) {

                var obj = this.PrizeList;
                console.log("obj", obj);
                var IsAdd = true;
                for (var i = 0; i < obj.length; i++) {
                    if (obj[i].AwardPoolID == item.ID) {
                        IsAdd = false;
                    }
                }
                if (IsAdd) {
                    this.PrizeList.push({
                        AwardType: 1,//优惠券
                        AwardCount: 0,
                        AwardProbability: 0,
                        AvatarUrl: item.Pic,
                        title: item.Name,
                        AwardMoney: item.Cash,
                        //优惠券ID
                        AwardPoolID: item.ID,

                    });
                } else {
                      self.$message.error("亲，已添加过该优惠券！！");
                }
                this.PrizeTypeShow = false;
            },

            SelectCoupon(item) {
                console.log(item);
                this.CouponShow = false;
                console.log(this.CouponActive);
                this.AddCoupon(item);
            },
            //优惠券数据查询
            GetAwardPoolList() {
                var self = this;
                self.ID = self.$route.query.ID;
                if (self.ID != 0) {
                    var op1 = {
                        url: "/MarketingModule/Promote/GetAwardPoolList",
                        data: {
                            TurnID: self.ID,
                        },
                        OnSuccess: function (data) {
                            var obj = data.data.Data;
                            self.dbkey = self.dbkey + obj[0].TurntableID;
                            self.PrizeList = obj;
                            setTimeout(function () {
                                if (self.TurnID != null) {
                                    var de = document.getElementsByName("Read");
                                    de.forEach(function (e) {
                                        e.setAttribute("readOnly", "readOnly");
                                    });
                                }
                            }, 500);
                        }

                    }
                    http.Post(op1);
                }
                else {
                    var op = {
                        url: "/MarketingModule/Promote/GetAwardPoolListInfo",
                        data: {
                            indexpage: self.pageindex,
                            rows: self.pageLength,
                            state: self.state,
                        },
                        OnSuccess: function (data) {
                            var obj = data.data.Data;
                            for (var i = 0; i < obj.length; i++) {
                                self.CouponsDataList = data.data.Data;
                            }
                        }
                };
                http.Post(op);
                }

              

            },
           
            setlocal: function (cname, cvalue) {
                localStorage.setItem(cname, cvalue);
            },
            getlocal: function (cname) {
                return localStorage.getItem(cname);
            },
          

            //跳转到模板页
            NewShowDetail: function (e) {
                var self = this;
                if (e.MaterialType == 5) {
                    self.$router.push({
                        name: "Store_NewPoster", query: {
                            MaterialType: e.MaterialType,
                            TmplID: e.ID,
                        }
                    })
                }
            },
            //获取摇奖模板
            StoregetTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTemplateList",
                    data: {
                        ClassID: self.ClassID,
                        pageIndex: self.Storecurrent,
                        MaterialType: self.MaterialType,
                        PageCount: self.StoredefaultPageSize,
                    },
                    OnSuccess: function (data) {
                        self.StoretableData = data.data.Data;
                        self.Storetotal = data.data.Total;
                        self.StoredefaultPageSize = data.data.RowCount;
                        self.Storecurrent = data.data.PageIndex;
                       

                    },
                };
                http.Post(op);
            },
            //获取模板图片
            StoreGetsrc: function (info) {
                return info.PreViewImg.replace("upload", "upload2");
            },
            //选择模板
            UseTemapate: function (e) {
                var self = this;
                self.MaterialType = e.MaterialType;
                self.TmplID = e.ID;

                console.log("MaterialType", self.MaterialType);
                console.log(" self.TmplID", self.TmplID);

                var op = {
                    url: "/MarketingModule/Material/GetMaterialTemplateList",
                    data: {
                        MaterialType: self.MaterialType,
                        PageCount: self.StoredefaultPageSize,
                        pageIndex: self.Storecurrent,
                        PushID: self.TmplID,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.StoredefaultPageSize = data.data.RowCount;
                        self.Storecurrent = data.data.PageIndex;
                        self.TmplID = data.data.Data[0]['ID'];
                        self.TmplName = data.data.Data[0]['Name'];
                        self.TmplSummary = data.data.Data[0]['Summary'];
                        self.TmplTime = data.data.Data[0]['InputTime'];
                        self.Img = data.data.Data[0]['PreViewImg'];
                        self.Model.Contents = data.data.Data[0]['Summary'];
                        console.log("self.TmplName", self.TmplName);
                        console.log("self.TmplSummary", self.TmplSummary);
                        console.log("self.TmplTime", self.TmplTime);
                    },
                };
                http.Post(op);
                self.loadData();
            },

            loadData: function () {
                var self = this;
                var op1 = {
                    url: "/MarketingModule/Promote/GetMaterialInfo",
                    data: {
                        id: 0,
                    },
                    OnSuccess: function (data) {
                        self.Model.Author = data.data.Author;
                        self.Model.MaterialAward.CustomID = data.data.MaterialAward.CustomID;
                        self.Model.ShareTime = util.formatDate3(new Date());
                    },
                };
                http.Post(op1);
            },

        },
        computed: {
            //计算属性
            swiper() {
                return this.$refs.mySwiper.$swiper
            }
        },
        watch: {

        },
        components: {
            Swiper,
            SwiperSlide
               //AwardPoolConfig,
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            console.log('Current Swiper instance object', this.swiper)
            this.swiper.slideTo(3, 1000, false)
            //生命周期，组装完成
            var self = this;
            if (self.$route.query.disabled == 1) {
                self.disabled = true;
            }
            self.ID = self.$route.query.ID;
           
            //加载优惠券
            self.GetAwardPoolList();
            //读取local恢复数据
            //self.setData();

            //加载模板
            self.StoregetTableData();
            if (self.ID != 0) {
                self.SetInteractionAward = false;
                self.EditInteractionAward = true;
            }
            else {
                self.SetInteractionAward = true;
                self.EditInteractionAward = false;
            }

        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
        directives: {
            swiper: directive
        }
    };
</script>
<style>
    .muban {
        display: grid;
        grid-template-columns: repeat(6,1fr);
        gap: 25px;
        padding: 25px;
        width: 100%;
        margin: 0 auto;
    }
    .l_prize_Type_main_item{
        margin-top: 15px;
    }
    .muban-item {
        text-align: center;
        min-height: 100%;
    }

        .muban-item:hover {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
            transition: all ease 0.3s;
        }

            .muban-item:hover .sc-txt {
                color: #2281f8
            }

    .sc-txt {
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: #3B3B3B;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        padding: 8px 5px;
    }

        .sc-txt:hover {
            color: #2281f8
        }


    .mintro {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        white-space: normal;
    }

    .mintro2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: left !important;
        margin: 0;
        font-size: 12px;
        height: 36px;
        white-space: normal;
    }

    .thumb-img1 {
        height: 150px;
        object-fit: cover;
        outline: none;
        background: url("../../../../src/assets/nourl.png");
        background-size: cover;
    }

    .thumb-img {
        cursor: pointer;
        /*min-height: 385px;*/
        border-radius: 5px;
        object-fit: cover;
        width:60%;
        outline: none;
        border: none;
        border-radius: 10px 10px 0 0;
        background: #EBEBEB url("../../../../src/assets/nourl.png") no-repeat center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

        .thumb-img:hover {
            opacity: 0.85;
        }

    .option {
        margin: 10px 0;
    }
    .l_invitation_three{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }
    .l-inviation-rt{
        background: #fff;
        min-height: 100%;
        flex: 0 1 60%;
        padding: 15px;
        border-radius: 5px;
    }
    .l-inviation-rt-tt{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .ant-input-number{
        width: 100% !important;
    }
    .l_select_coupon_item{
        display: flex;
        align-items: center;
    }
    .l_select_coupon_item .title{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    .l_select_coupon_item img{
        width: 80px;
        margin:0 10px ;
    }
    .l-inviation-rt-pf-itm img{
        width: 100px;
        margin: 15px;
    }
    .l-inviation-rt-pf-itm{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .pf-itm-title{
        font-size: 16px;
        color: #000;
        margin-bottom: 20px;
    }
    .pf-itm-info{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    .pf-itm-input{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .pf-itm-input .ant-form-item{
        margin: 0;
    }
    .l-inviation-left{
        flex: 0 1 40%;
        overflow: hidden;
        
    }
    .l-inviation-btns{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
        padding: 10px 15px;
    }
    .l_invitation_next_btn{
        text-align: right;
    }
    .l_invitation_next_btn button{
        background: #2281f8;
        color: #fff;
        border: none;
        padding: 2px 10px;
        border-radius: 3px;
    }
</style>
